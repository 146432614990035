import React from 'react'
import "../CSS/NotFound.css"
function NotFound() {
  return (
    <div className="not_found">
        <h1>404</h1>
        <p>NOT FOUND</p>
    </div>
  )
}

export default NotFound