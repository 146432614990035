import axios from "axios";
import { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Loading from "./Loading";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import { useNavigate } from "react-router-dom";

function OnlineClient() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [datax, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  const navigate = useNavigate();

  function secur() {
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/clients`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((result) => {
        setIsLoading(false);
        setData(result.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled");
        }
        //  else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
  }

  useEffect(secur, []);
  const renderClients = (
    <div className="App">
      <Navbar />
      <div style={{ position: "relative", top: "55px" }}>
        <div className="header">LIST OF CLIENTS</div>
        <div className="container">
          <table className="table">
            <tr className="headerName">
              <th style={{ textAlign: "left", padding: "8px" }}>CLIENT NAME</th>
              <th className="clients_media">BUSINESS LINE</th>
              <th className="clients_media">TELEPHONE</th>
              <th className="clients_media">EMAIL</th>
              <th className="clients_media">CITY</th>
            </tr>
            <tbody className="Info">
              {datax.map((data) => (
                <tr className=" table-row ">
                  <td style={{ padding: "8px" }}>{data.OM_CLIENT_NAME}</td>
                  <td>{data.OM_CLIENT_BUSINESS_LINE}</td>
                  <td className="tbody_right">{data.OM_CLIENT_TELEPHONE}</td>
                  <td>{data.OM_CLIENT_EMAIL_ADDRESS}</td>
                  <td>{data.OM_CLIENT_CITY}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderClients}</div>;
}

export default OnlineClient;
