import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import { viewBid } from "../redux/actions/actions";
import moment from "moment";
import JwtRequestHandler from "../utils/JwtRequestHandler";

const UnapprovedBids = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [datas, setData] = useState([]);
  const privilige = useSelector((state) => state.loginreducer);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");

  function cello() {
    // const source = axios.CancelToken.source();
    const suppid = privilige[0].id;
    axios
      .post(
        `${BASEURL}/approv`,
        { supplierid: suppid },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(cello, []);

  const handleSub = (suppid, item, chgd, rfqid, updby, vat, vatinc, email) => {
    setIsLoading(true);
    const SUPPLIERID = suppid;
    const ITEMCODE = item;
    const CHARGEDAMT = chgd;
    const RFQID = rfqid;
    const UPDATEDBY = updby;
    const APPROVEDBY = updby;
    const VATAMT = vat;
    const CHARGEDAMTINCLUSIVE = vatinc;
    const EMAILID = email;
    const SUCCESS_STATUS = "";
    const ERROR_STATUS = "";
    axios
      .post(
        `${BASEURL}/approval`,
        {
          SUPPLIERID,
          CHARGEDAMT,
          RFQID,
          UPDATEDBY,
          APPROVEDBY,
          SUCCESS_STATUS,
          ERROR_STATUS,
          ITEMCODE,
          VATAMT,
          CHARGEDAMTINCLUSIVE,
          EMAILID,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        alert(response.data.message);
        navigate("/approvedbids");
      });
  };

  function daysRemaining(data) {
    const expiryDate = new Date(data);
    const curDate = new Date();
    const timeDiff = expiryDate.getTime() - curDate.getTime();
    var dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (dayDiff <= 0) {
      dayDiff = 0;
    } else {
      return dayDiff;
    }
    return dayDiff;
  }

  const renderUnapproved = (
    <div>
      <Navbar />
      <div style={{ position: "relative", top: "60px" }}>
        <div className="header">UNAPPROVED BIDS PAGE</div>
        <div className="container">
          <table>
            <thead>
              <tr className="headerName">
                <th style={{ textAlign: "center" }}>BID ID</th>
                <th>BUYER</th>
                <th style={{ width: "250PX", textAlign: "left" }}>NARRATION</th>
                <th>SUBMITTED BY</th>
                <th style={{ textAlign: "left" }}>CREATED DATE</th>
                <th style={{ textAlign: "left" }}>EXPIRY DATE</th>
                <th style={{ textAlign: "left" }}>DAYS PENDING</th>
                <th>STATUS</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            {datas.length ? (
              <tbody>
                {datas.map((data) => {
                  return (
                    <tr className="table-row" key={data}>
                      <td style={{ textAlign: "center" }}>{data.OT_RFQ_ID}</td>
                      <td>{data.OT_RFQ_PROCESSED_BY}</td>
                      <td>{data.OT_RFQ_NARRATION}</td>
                      <td style={{ textAlign: "center" }}>
                        {data.EMAILADDRESS.split("@").shift()}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {moment(data.OT_RFQ_DATE).format("DD/MM/YYYY")}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {moment(data.OT_RFQ_EXPIRY_DATE).format("DD/MM/YYYY")}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {daysRemaining(data.OT_RFQ_EXPIRY_DATE)}
                      </td>
                      <td>{data.OT_RFQ_STATUS}</td>
                      <td>
                        <div className="Approval_button2">
                          <button
                            className="View"
                            onClick={() => {
                              dispatch(viewBid(data.OT_RFQ_ID));
                              navigate("/updatesellerbid");
                            }}
                          >
                            View
                          </button>
                          <button
                            className="Approve"
                            onClick={() => {
                              if (privilige[0].canApprove !== true) {
                                alert(
                                  "ALERT! You don't have approval privileges!"
                                );
                              } else {
                                const confirmBox = window.confirm(
                                  "Are you sure you want to approve this?"
                                );
                                if (confirmBox === true) {
                                  handleSub(
                                    data.SUPPLIERID,
                                    data.OT_RFQ_ITEM_CODE,
                                    data.OT_RFQ_ITEM_RATE_CHARGED,
                                    data.OT_RFQ_ID,
                                    privilige[0].createdby,
                                    data.VATAMOUNT,
                                    data.AMOUNTINCLUSIVEVAT,
                                    privilige[0].email
                                  );
                                }
                              }
                            }}
                          >
                            Approve Bid
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <br></br>
                <th colSpan={9}>
                  <b>No Unapproved bids</b>
                </th>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderUnapproved}</div>;
};

export default UnapprovedBids;
