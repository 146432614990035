import axios from "axios";
import { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewBid } from "../redux/actions/actions";
import Footer from "./Footer";
import Loading from "./Loading";
import moment from "moment";
import "../CSS/BidDetails.css";
import JwtRequestHandler from "../utils/JwtRequestHandler";

function Listofbids() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const dispatch = useDispatch();
  const idUser = useSelector((state) => state.loginreducer);
  const navigate = useNavigate();
  const [datas, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");

  function getStatus() {
    // const source = axios.CancelToken.source()
    axios
      .get(`${BASEURL}/listofbids`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(getStatus, []);

  function getMsg(rfqid) {
    const id = idUser[0].email;
    axios
      .post(
        `${BASEURL}/whapplied`,
        {
          id: id,
          rfqid: rfqid,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        if (response.data.val === 1) {
          alert(
            "You have applied for the bid. To check details, go to unapproved/approved page."
          );
        } else if (response.data.val === 2) {
          alert("You have Not applied for the bid. To apply, click on 'View'.");
        } else if (response.data.val === 3) {
          alert("Server Error. Please wait and try again.");
        }
      });
  }

  const dateFilteredData = datas.filter((data) => {
    if (startDate && endDate) {
      return (
        data.OT_RFQ_EXPIRY_DATE >= startDate &&
        data.OT_RFQ_EXPIRY_DATE <= endDate
      );
    } else if (startDate) {
      return data.OT_RFQ_EXPIRY_DATE >= startDate;
    } else if (endDate) {
      return data.OT_RFQ_EXPIRY_DATE <= endDate;
    } else {
      return data;
    }
  });

  function daysRemaining(data) {
    const expiryDate = new Date(data);
    const curDate = new Date();
    const timeDiff = expiryDate.getTime() - curDate.getTime();
    var dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (dayDiff <= 0) {
      dayDiff = 0;
    } else {
      return dayDiff;
    }
    return dayDiff;
  }

  const renderBidList = (
    <div style={{ position: "relative", top: "55px" }}>
      <Navbar />

      <div className="header">LIST OF ACTIVE RFQs</div>
      <div className="container">
        <div className="Date_filter">
          <span className="Start_date">Start Date:</span>
          <input
            className="start_input"
            type="date"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          ></input>
          <span className="End_Date">End Date:</span>
          <input
            className="start_input"
            type="date"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          ></input>
        </div>

        <table className="scroll">
          <thead>
            <tr className="headerName">
              <th style={{ textAlign: "center" }}>BID ID</th>
              <th>BUYER</th>
              <th style={{ width: "250px" }}>NARRATION</th>
              <th>RFQ DATE</th>
              <th>EXPIRY DATE</th>
              <th>DAYS LEFT</th>
              <th>Options</th>
              <th>Applied</th>
            </tr>
          </thead>

          {dateFilteredData.length ? (
            <tbody className="Info">
              {dateFilteredData.map((data) => (
                <tr className="table-row" key={data.OT_RFQ_ID}>
                  <td className="tbody">{data.OT_RFQ_ID}</td>
                  <td>{data.CLIENTNAME}</td>
                  <td>{data.OT_RFQ_NARRATION}</td>
                  <td>{moment(data.OT_RFQ_DATE).format("DD-MM-YYYY")}</td>
                  <td>
                    {moment(data.OT_RFQ_EXPIRY_DATE).format("DD-MM-YYYY")}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {daysRemaining(data.OT_RFQ_EXPIRY_DATE)}
                  </td>
                  <td>
                    <button
                      className="to_go_to_bidspages"
                      onClick={() => {
                        dispatch(viewBid(data.OT_RFQ_ID));
                        navigate("/BidDetails");
                      }}
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <button
                      className="to_go_to_bidspages"
                      onClick={(e) => {
                        e.preventDefault();
                        getMsg(data.OT_RFQ_ID);
                      }}
                    >
                      Check
                    </button>
                  </td>
                </tr>
              ))}
              ;
            </tbody>
          ) : (
            <tbody>
              <br></br>
              <br></br>
              <tr>
                <td
                  style={{ textAlign: "center", fontWeight: "bold" }}
                  colSpan={8}
                >
                  No Active RFQs!
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderBidList}</div>;
}

export default Listofbids;
