export const logIn = (data) => {
  return { type: "LOGIN", data: data };
};
export const viewBid = (data) => {
  return { type: "BID", data: data };
};
export const alreadyApplied = (data) => {
  return { type: "ALRAPP", data: data };
};
export const logInStatus = (data) => {
  return { type: "LOGINSTAT", data: data };
};
export const logInStatusBuyer = (data) => {
  return { type: "LOGINSTATBUYER", data: data };
};
export const buyerBid = (data) => {
  return { type: "BUYERBID", data: data};
}
export const refreshLogin = (accessToken,refreshToken) =>{
  return { type: "REFRESH", data: {accessToken, refreshToken}}
}