const initialState = false;

const loginstateredbuyer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGINSTATBUYER":
      return action.data;

    default:
      return state;
  }
};

export default loginstateredbuyer;