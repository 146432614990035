import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import axios from "axios";
import moment from "moment";
import BuyerNavbar from "./BuyerNavbar";
import { Link, useNavigate } from "react-router-dom";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import "../CSS/BuyerInventory.css";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";

function BuyerInventory() {
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [gotoPage, setGotoPage] = useState();
  const [inventorynamefilter, setInventorynamefilter] = useState("");
  const [inventorycodefilter, setInventorycodefilter] = useState(null);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");

  function buyerint() {
    // const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/iteminventorydata`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(buyerint, []);

  const filteredData = data
    .filter((item) => {
      if (inventorynamefilter === "") {
        return item;
      } else if (
        item.ITEMGENERICNAME.toLowerCase().includes(
          inventorynamefilter.toLowerCase()
        )
      ) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      if (inventorycodefilter === null) {
        return item;
      } else if (item.ITEMCODE.includes(inventorycodefilter)) {
        return item;
      } else {
        return null;
      }
    });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <BuyerNavbar />
      <div style={{ position: "relative", top: "55px" }}>
        {/* <div className="Inventory">
          Items Inventory
          <Link className="AddItemLink" to="/buyerinventoryadditem">
            <button className="AddItemButton" type="button">
              ADD ITEM
            </button>
          </Link>
        </div> */}

        <div className="Main2">
          <div className="container">
            <div className="filter_section">
              <div>
                <span>Search By Item Code</span>
                <input
                  className="item_code_input"
                  type="number"
                  placeholder="Insert Item Code.."
                  onChange={(e) => {
                    setInventorycodefilter(e.target.value);
                  }}
                ></input>
                <span className="search_by_item_code">Search By Item Name</span>
                <input
                  className="item_code_input"
                  type="text"
                  placeholder="Insert Item Name.."
                  onChange={(e) => {
                    setInventorynamefilter(e.target.value);
                  }}
                ></input>
              </div>

              <Link className="AddItemLink" to="/buyerinventoryadditem">
                <button className="AddItemButton" type="button">
                  ADD ITEM
                </button>
              </Link>
            </div>

            <table className="scroll">
              <thead>
                <tr className="headerName">
                  <th
                    style={{
                      width: " 63px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Item Code{" "}
                  </th>
                  <th
                    style={{
                      width: " 147px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    Item Name
                  </th>
                  <th
                    style={{
                      width: " 67px",
                      textAlign: "center",
                    }}
                  >
                    Part No
                  </th>
                  <th
                    style={{
                      width: "243px",
                      textAlign: "center",
                    }}
                  >
                    Item Desc.
                  </th>
                  <th
                    style={{
                      width: " 100px",
                      textAlign: "center",
                    }}
                  >
                    Classification
                  </th>
                  <th
                    style={{
                      width: " 100px",
                      textAlign: "center",
                    }}
                  >
                    Category
                  </th>
                  <th
                    style={{
                      width: "96",
                      textAlign: "center",
                    }}
                  >
                    Sub Category
                  </th>
                  <th
                    style={{
                      width: " 76px",
                      textAlign: "center",
                    }}
                  >
                    Base UOM
                  </th>
                  <th
                    style={{
                      width: " 76px",
                      textAlign: "center",
                    }}
                  >
                    Alternate UOM
                  </th>
                  <th
                    style={{
                      width: " 80px",
                      textAlign: "center",
                    }}
                  >
                    Conv Factor
                  </th>
                  <th
                    style={{
                      width: " 56px",
                      textAlign: "center",
                    }}
                  >
                    Vatable
                  </th>
                  <th
                    style={{
                      width: " 76px",
                      textAlign: "center",
                    }}
                  >
                    Created By
                  </th>
                  <th
                    style={{
                      width: " 92px",
                      textAlign: "center",
                    }}
                  >
                    Created Date
                  </th>
                </tr>
              </thead>
              <tbody className="Info">
                {currentItems.map((item) => (
                  <tr className="table-row" key={item.ITEMCODE}>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.ITEMCODE}
                    </td>
                    <td>{item.ITEMGENERICNAME}</td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.PARTNO}
                    </td>
                    <td>{item.ITEMDESCRIPTION}</td>
                    <td>{item.CLASSIFICATION}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.ITEMCATEGORY.length > 0 ? (
                        <div>{item.ITEMCATEGORY}</div>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.SUBCATEGORY.length > 0 ? (
                        <div>{item.SUBCATEGORY}</div>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>{item.BASEUOM}</td>
                    <td style={{ textAlign: "center" }}>{item.ALTUOM}</td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.CONVFACTOR}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {item.VATABLE}
                    </td>
                    <td>{item.CREATEDBY}</td>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {moment(item.CREATEDDATE).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="Page">
              <button
                className="PageButton"
                style={
                  currentPage <= 1
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                onClick={() => {
                  setCurrentPage(1);
                }}
              >
                &lt;&lt;
              </button>
              <button
                style={
                  currentPage <= 1
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                className="PageButton"
                onClick={() => {
                  setCurrentPage(Number(currentPage) - 1);
                }}
              >
                <FaLessThan />
              </button>

              <div style={{ display: "block" }}>
                <input
                  className="PageInput"
                  name="gotopage"
                  min={0}
                  type="number"
                  placeholder="Insert page number"
                  onChange={(e) => {
                    setGotoPage(e.target.value);
                  }}
                ></input>
                <div style={{ marginTop: "10px" }} className="CurrentPage">
                  Current Page: {currentPage}
                </div>
              </div>
              <button
                className="PageButton"
                style={{ color: "white", marginLeft: "13px" }}
                onClick={() => {
                  if (
                    gotoPage <= 0 ||
                    gotoPage === null ||
                    gotoPage === "" ||
                    gotoPage === undefined
                  ) {
                    setCurrentPage(1);
                  } else if (
                    gotoPage > Math.ceil(Number(filteredData.length / 20))
                  ) {
                    setCurrentPage(Math.ceil(Number(filteredData.length / 20)));
                  } else {
                    setCurrentPage(gotoPage);
                  }
                }}
              >
                Go-to page
              </button>
              <button
                className="PageButton"
                style={
                  currentPage >= Math.ceil(Number(filteredData.length / 20))
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                onClick={() => {
                  setCurrentPage(Number(currentPage) + 1);
                }}
              >
                <FaGreaterThan />
              </button>
              <button
                className="PageButton"
                style={
                  currentPage >= Math.ceil(Number(filteredData.length / 20))
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
                onClick={() => {
                  setCurrentPage(Math.ceil(Number(data.length / 20)));
                }}
              >
                &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BuyerInventory;
