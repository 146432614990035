import React from 'react'
import '../CSS/Loading.css'
const Loading = () => {
  return (
    <div>
    <div className='centreLoader'>
        <div>
    <div className="loader">
   </div>
   <h3>Loading..</h3>
   </div>
   </div>
</div>
  )
}

export default Loading