import React, { useEffect, useState } from "react";
import BuyerNavbar from "./BuyerNavbar";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "../CSS/NewQuotation.css";
import Select from "react-select";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";

function UpdateQuotation() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const buyerdetails = useSelector((state) => state.loginreducer);
  const client = buyerdetails[0].id;
  const bidId = useSelector((state) => state.bidreducer);
  const processedby = buyerdetails[0].username;
  const [valuesRefresher, setValRef] = useState(false);
  const [datas, setDatas] = useState([]);
  const [pageData, setPageData] = useState([]);
  const navigate = useNavigate();
  const [expiry, setExpiry] = useState();
  const [narration, setNarration] = useState("");
  const [activedays, setActivedays] = useState();
  const [rfqid, setRfqid] = useState();
  const [status, setStatus] = useState("");
  const [itemTableData, setItemTableData] = useState([]);
  const [itemCodeInventory, setItemCodeInventory] = useState([]);
  const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const isApproved = location.state.isApproved;
  const authToken = window.sessionStorage.getItem("auth-token");

  function getData() {
    // const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/updatequotation`,
        { id: client, rfqid: bidId },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setPageData(response.data);
        const processedData = processData(response.data);
        setDatas(processedData);
        setRfqid(response.data[0].OT_RFQ_ID);
        setNarration(response.data[0].OT_RFQ_NARRATION);
        setActivedays(response.data[0].OT_RFQ_ACTIVE_DAYS);
        setExpiry(response.data[0].OT_RFQ_EXPIRY_DATE);
        setStatus(response.data[0].OT_RFQ_STATUS);
        setItemTableData({
          rfqid: response.data[0].OT_RFQ_ID,
          client: client,
          itemcode: "",
          itemname: "",
          quantity: null,
          uom: "",
          budget: null,
          techspecs: "",
          imgreq: null,
        });
        axios
          .get(`${BASEURL}/iteminventorydata`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setItemCodeInventory(response.data);
            setIsLoading(false);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Request Cancelled");
            } else if (axios.AxiosError) {
              navigate("/authfailed");
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        //  else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(getData, []);

  const processData = (data) => {
    const processedArray = data.map((item) => ({
      rfqid: item.OT_RFQ_ID,
      client: item.OT_RFQ_ID_FROM_CLIENT,
      itemcode: item.OT_RFQ_ITEM_CODE,
      itemname: item.OT_RFQ_ITEM_NAME,
      quantity: item.OT_RFQ_ITEM_QTY_REQUIRED,
      uom: item.OT_RFQ_ITEM_UOM,
      budget: item.OT_RFQ_ITEM_BUDGET_SET,
      techspecs: item.OT_RFQ_ITEM_TECHNICAL_SPECS,
      imgreq: item.OT_RFQ_IMAGE_REQUIRED,
    }));
    return processedArray;
  };

  function calculateDays(e) {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    const timeDiff = selectedDate.getTime() - currentDate.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    setActivedays(dayDiff);
    setExpiry(moment(selectedDate).format("DD/MM/YYYY"));
  }

  const handleItemInput = (e) => {
    const { name, value } = e.target;
    setItemTableData({ ...itemTableData, [name]: value });
  };

  const handleCodeInput = (e) => {
    const selectedValue = e.value;
    const selectedOption = itemCodeInventory.find(
      (obj) => obj.ITEMCODE === selectedValue
    );
    setItemTableData(
      selectedOption
        ? {
            ...itemTableData,
            itemname: selectedOption.ITEMGENERICNAME,
            itemcode: selectedValue,
            uom: selectedOption.BASEUOM,
            techspecs: selectedOption.ITEMDESCRIPTION,
          }
        : {
            ...itemTableData,
            itemname: "",
            itemcode: "",
            uom: "",
            techspecs: "",
          }
    );
  };

  const handleNameInput = (e) => {
    const selectedValue = e.value;
    const selectedOption = itemCodeInventory.find(
      (obj) => obj.ITEMGENERICNAME === selectedValue
    );
    setItemTableData(
      selectedOption
        ? {
            ...itemTableData,
            itemname: selectedValue,
            itemcode: selectedOption.ITEMCODE,
            uom: selectedOption.BASEUOM,
            techspecs: selectedOption.ITEMDESCRIPTION,
          }
        : {
            ...itemTableData,
            itemname: "",
            itemcode: "",
            uom: "",
            techspecs: "",
          }
    );
  };

  const handleAddRow = (e) => {
    if (isApproved) {
      alert("Can't Add Items: The RFQ has already been approved.");
    } else if (
      itemTableData.itemcode == "" ||
      itemTableData.itemname == "" ||
      itemTableData.quantity == 0 ||
      itemTableData.quantity == null ||
      itemTableData.uom == "" ||
      itemTableData.budget == 0 ||
      itemTableData.budget == null ||
      itemTableData.techspecs == ""
    ) {
      alert(
        "Please fill valid values in all inputs provided before adding an item."
      );
    } else {
      datas.push(itemTableData);
      console.log(datas);
      setItemTableData({
        rfqid: rfqid,
        client: client,
        itemcode: "",
        itemname: "",
        quantity: null,
        uom: "",
        budget: null,
        techspecs: "",
        imgreq: null,
      });
      document.getElementById("itemcodeinput").value = "";
      document.getElementById("itemnameinput").value = "";
      document.getElementById("quantityinput").value = "";
      document.getElementById("uominput").value = "Select";
      document.getElementById("budgetinput").value = "";
      document.getElementById("techinput").value = "";
      document.getElementById("imgreqinput").value = "Select";
    }
  };

  const handleDeleteRow = (e, index) => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmBox === true) {
      e.preventDefault();
      const newData = [...datas];
      newData.splice(index, 1);
      setDatas(newData);
    } else {
      e.preventDefault();
    }
  };

  function handleSubmit() {
    if (status === "CLOSED" || status === "ACTIVE") {
      alert(
        "Cannot update an ACTIVE/CLOSED RFQ. Please create a new RFQ instead."
      );
    } else if (expiry == "" || expiry == null || narration == "") {
      alert(
        "Cannot save without header details. Please fill in header details"
      );
    } else {
      let error = false;
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/saveupdatedheader`,
          {
            rfqid,
            client,
            expiry,
            activedays,
            narration,
            processedby,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          for (var i = 0; i < datas.length; i++) {
            axios
              .post(
                `${BASEURL}/saveupdateditems`,
                { data: datas[i] },
                {
                  headers: {
                    "auth-token": authToken,
                  },
                }
              )
              .then((response) => {
                console.log(response.data.message);
              })
              .catch((err) => {
                error = true;
              });
          }
          setIsLoading(false);
          if (!error) {
            alert("RFQ has been updated and saved successfully.");
            navigate("/myquotations");
          } else {
            alert("An Error Occured on the server. Please try again.");
          }
        });
    }
  }

  function calcTotalBudget(array) {
    const totalBudget = array.reduce(
      (total, current) => Number(total) + Number(current.budget),
      0
    );
    return totalBudget;
  }
  // styling given react-select
  const styleforselect = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid",
      height: "10px",
    }),
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <BuyerNavbar />
      <div style={{ position: "relative", top: "50px" }}>
        <div>
          <form>
            {pageData.map((data, index) => {
              if (index === 0) {
                return (
                  <div className="boxshadow" key={data.OT_RFQ_ID}>
                    <h3>Request for Quotation</h3>
                    <br></br>
                    <span>RFQ Status: {status}</span>
                    <div className="bo">
                      <div className="Req_for">
                        <div className="form__group field">
                          <p style={{ width: "120px" }}>Buyer ID:</p>

                          <input
                            className="effect"
                            type="text"
                            disabled
                            value={client}
                          />
                          <span className="focus-border"></span>
                        </div>

                        <div className="Requ">
                          <p style={{ width: "120px" }}>RFQ ID:</p>

                          <input
                            className="effect"
                            type="number"
                            disabled
                            value={rfqid}
                          ></input>
                          <span className="focus-border"></span>
                        </div>

                        <div>
                          <p style={{ width: "120px" }}>Date of Creation:</p>

                          <input
                            className="effect"
                            type="text"
                            disabled
                            value={moment(data.OT_RFQ_DATE).format(
                              "DD/MM/YYYY"
                            )}
                          ></input>
                        </div>
                      </div>
                      <div className="Req_for">
                        <div>
                          <p>Expiry Date:</p>

                          <input
                            style={{ width: "160px" }}
                            type="date"
                            required
                            defaultValue={moment(
                              data.OT_RFQ_EXPIRY_DATE
                            ).format("YYYY-MM-DD")}
                            min={new Date().toISOString().split("T")[0]}
                            onChange={(e) => {
                              calculateDays(e);
                            }}
                          ></input>
                        </div>

                        <div className="form__group field">
                          <p>Active Days:</p>

                          <input
                            className="effect"
                            type="number"
                            disabled
                            value={
                              activedays ? activedays : data.OT_RFQ_ACTIVE_DAYS
                            }
                          ></input>
                        </div>
                      </div>

                      <div className="Req_for">
                        <div className="form__group_field1">
                          <p className="Proceed_by">Processed By:</p>

                          <input
                            className="effect"
                            style={{ color: "black" }}
                            type="text"
                            disabled
                            value={data.OT_RFQ_PROCESSED_BY}
                          ></input>
                        </div>

                        <div>
                          <p className="Narration2">Narration:</p>

                          <textarea
                            style={{ width: "100%" }}
                            s
                            className="textarea"
                            type="text"
                            name="narration"
                            required
                            defaultValue={data.OT_RFQ_NARRATION}
                            onChange={(e) => {
                              setNarration(e.target.value);
                            }}
                            placeholder="Type Narration.."
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <div className="box-shadow1">
              <h3>Items Required:</h3>
              <br></br>
              <div className="Request1">
                <div className="form__group field">
                  <p className="Item_code">Item Code:</p>
                  <div className="col-3">
                    {/* <select
                      class="Itemcode"
                      className="effect-1"
                      style={{ width: "170px" }}
                      id='itemcodeinput'
                      name='itemcode'
                      required
                      value={itemTableData.itemcode}
                      onChange={(e) => { handleCodeInput(e) }}
                    >
                      <option value={''}>Select</option>
                      {itemCodeInventory.map((item) => (
                        <option value={item.ITEMCODE}>{item.ITEMCODE}</option>
                      ))}
                    </select> */}
                    <Select
                      className="Itemcode"
                      styles={styleforselect}
                      id="itemcodeinput"
                      name="itemcode"
                      required
                      value={
                        itemTableData.itemcode
                          ? {
                              label: itemTableData.itemcode,
                              value: itemTableData.itemcode,
                            }
                          : { label: "Select...", value: "" }
                      }
                      onChange={(e) => {
                        handleCodeInput(e);
                      }}
                      options={itemCodeInventory.map((item) => ({
                        label: item.ITEMCODE,
                        value: item.ITEMCODE,
                      }))}
                    />
                    <span className="focus-border"></span>
                  </div>
                </div>
                <div className="form__group field">
                  <p className="Name">Name:</p>
                  <div className="col-3">
                    {/* <select
                      // class="Name_required"
                      className="effect-1"
                      id='itemnameinput'
                      name='itemname'
                      // style={{ height: "25px" }}
                      required
                      value={itemTableData.itemname}
                      // style={{ width: "170px" }}
                      onChange={(e) => { handleNameInput(e) }}
                    >
                      <option value={''}>Select</option>
                      {itemCodeInventory.map((item) => (
                        <option value={item.ITEMGENERICNAME}>{item.ITEMGENERICNAME}</option>
                      ))}
                    </select> */}
                    <Select
                      required
                      className="Name_required"
                      styles={styleforselect}
                      id="itemnameinput"
                      name="itemname"
                      value={
                        itemTableData.itemname
                          ? {
                              label: itemTableData.itemname,
                              value: itemTableData.itemname,
                            }
                          : { label: "Select...", value: "" }
                      }
                      onChange={(e) => {
                        handleNameInput(e);
                      }}
                      options={itemCodeInventory.map((item) => ({
                        label: item.ITEMGENERICNAME,
                        value: item.ITEMGENERICNAME,
                      }))}
                    />
                    <span className="focus-border"></span>
                  </div>
                </div>
              </div>
              <div className="Request1">
                <div className="form__group field">
                  <p>Quantity:</p>
                  <div className="col-3">
                    <input
                      className="effect-1"
                      id="quantityinput"
                      name="quantity"
                      required
                      type="number"
                      placeholder="Qty Required.."
                      min={1}
                      onChange={(e) => {
                        handleItemInput(e);
                      }}
                    ></input>

                    <span className="focus-border"></span>
                  </div>
                </div>

                {/* <div className='Request1'> */}
                <div className="form__group field">
                  <p className="Name">UOM:</p>
                  <div className="col-3">
                    <input
                      className="effect-1"
                      style={{ width: "170px", marginLeft: "7px" }}
                      id="uominput"
                      name="uom"
                      value={itemTableData.uom}
                    ></input>
                    <span className="focus-border"></span>
                  </div>
                </div>
                <div className="form__group field">
                  <p className="Name1">Budget:</p>
                  <div className="col-3">
                    <input
                      className="effect-1"
                      id="budgetinput"
                      name="budget"
                      type="number"
                      min={1}
                      required
                      placeholder="Rate Expected.."
                      onChange={(e) => {
                        handleItemInput(e);
                      }}
                    ></input>
                    <span className="focus-border"></span>
                  </div>
                </div>
                <div className="form__group field">
                  <p className="Name2">Request Images:</p>
                  <div className="col-3">
                    <select
                      className="effect-1"
                      style={{ width: "170px" }}
                      id="imgreqinput"
                      required
                      name="imgreq"
                      onChange={(e) => {
                        handleItemInput(e);
                      }}
                    >
                      <option value={"none"} disabled hidden selected>
                        Select
                      </option>
                      <option value={Number(1)}>Yes</option>
                      <option value={Number(0)}>No</option>
                    </select>
                    <span className="focus-border"></span>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="tech2" style={{ display: "flex" }}>
                <p>Technical Specifications:</p>
                <div className="col-3">
                  <input
                    // style={{ width: "350px" }}
                    className="effect-1"
                    // class="textarea"
                    id="techinput"
                    style={{ width: "383%" }}
                    name="techspecs"
                    // required
                    type="text"
                    placeholder="Specifications.."
                    defaultValue={itemTableData.techspecs}
                    onChange={(e) => {
                      handleItemInput(e);
                    }}
                  ></input>
                  <span class="focus-border"></span>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="log_btn3"
              onClick={(e) => {
                const confirmBox = window.confirm(
                  "Are you sure you want to add this item?"
                );
                if (confirmBox === true) {
                  handleAddRow();
                } else {
                  e.preventDefault();
                }
              }}
            >
              Add Item
            </button>
          </form>
        </div>

        <br></br>
        <div
          style={
            datas.length > 0
              ? { visibility: "visible" }
              : { visibility: "hidden" }
          }
        >
          <h3 style={{ marginLeft: "19px", marginBottom: "15px" }}>
            Items Added
          </h3>
          <div className="Tab">
            <table className="table">
              <thead>
                <tr className="tr">
                  <th className="th">Item Code</th>
                  <th className="th">Item Name</th>
                  <th className="th">Quantity</th>
                  <th className="th">UOM</th>
                  <th className="th">Budget</th>
                  <th className="th">Technical Specs</th>
                  <th className="th">Image Required</th>
                  <th className="th">Actions</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data, index) => (
                  <tr key={data.itemcode}>
                    <td className="td">
                      <input
                        className="input"
                        type="text"
                        name="itemcode"
                        value={data.itemcode}
                        placeholder="Insert Item code"
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        type="text"
                        name="itemname"
                        placeholder="Insert Item name"
                        value={data.itemname}
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        type="number"
                        name="quantity"
                        min={1}
                        placeholder="Insert quantity"
                        defaultValue={data.quantity}
                        onChange={(e) => {
                          data.quantity = e.target.value;
                        }}
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        name="uom"
                        value={data.uom}
                      ></input>
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        type="number"
                        name="budget"
                        min={1}
                        defaultValue={data.budget}
                        placeholder="Insert budget"
                        onChange={(e) => {
                          setValRef(!valuesRefresher);
                          data.budget = e.target.value;
                        }}
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        type="text"
                        name="techspecs"
                        placeholder="Insert tech specs"
                        value={data.techspecs}
                      />
                    </td>
                    <td className="td">
                      <select
                        className="input"
                        style={{ width: "130px" }}
                        required
                        name="imgreq"
                        defaultValue={data.imgreq ? 1 : 0}
                        onChange={(e) => {
                          data.imgreq = e.target.value;
                        }}
                      >
                        <option value={"none"} disabled hidden selected>
                          Select
                        </option>
                        <option value={Number(1)}>Yes</option>
                        <option value={Number(0)}>No</option>
                      </select>
                    </td>
                    <td className="td">
                      <button
                        className="btnd"
                        onClick={(e) => {
                          handleDeleteRow(e, index);
                        }}
                      >
                        Delete Row
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <div className="total_budget">
                <h3>Total Items</h3>
                <input
                  style={{
                    color: "black",
                    marginLeft: "33px",
                    height: "30px",
                    background: "transparent",
                    border: "none",
                    borderBottom: "1.5px solid #565050",
                    marginLeft: " 46px",
                  }}
                  type="number"
                  value={datas.length}
                  disabled
                ></input>
              </div>
              <div className="total_budget">
                <h3>Total Budget</h3>
                <input
                  style={{
                    color: "black",
                    marginLeft: "17px",
                    height: "30px",
                    border: "none",
                    borderBottom: "1.5px solid #565050",
                    marginLeft: " 30px",
                  }}
                  type="number"
                  value={calcTotalBudget(datas)}
                  disabled
                ></input>
              </div>
              <button
                className="Save_update"
                onClick={(e) => {
                  e.preventDefault();
                  const confirmBox = window.confirm(
                    "Are you sure you want to submit?"
                  );
                  if (confirmBox === true) {
                    handleSubmit(e);
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                Save & Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateQuotation;
