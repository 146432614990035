import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./index.css";
import Login from "./components/Login";
import "../src/CSS/Table.css";
import "../src/CSS/navbar.css";
import "../src/CSS/BidDetails.css";
import Listofbids from "./components/Listofbids";
import OnlineClient from "./components/Onlineclients";
import BidDetails from "./components/BidDetails";
import ForgotPwd from "./components/ForgotPwd";
import WonLost from "./components/WonLost";
import UnapprovedBids from "./components/UnapprovedBids";
import ApprovedBids from "./components/ApprovedBids";
import { useSelector } from "react-redux";
import RegisterBuyer from "./buyerModule/Register";
import RegisterBuyerUser from "./buyerModule/RegisterBuyerUser";
import Buyerhomepage from "./buyerModule/Buyerhomepage";
import UpdateQuotation from "./buyerModule/UpdateQuotation";
import BuyerUsers from "./buyerModule/BuyerUsers";
import DetailsOfBid from "./buyerModule/DetailsOfBid";
import MyQuotations from "./buyerModule/MyQuotations";
import UpdateSellerBid from "./components/UpdateSellerBid";
import NewQuotation from "./buyerModule/NewQuotation";
import NotFound from "./buyerModule/NotFound";
import BuyerInventory from "./buyerModule/BuyerInventory";
import BuyerInventoryAddItem from "./buyerModule/BuyerInventoryAddItem";
import SellerRegistration from "./components/SellerRegistration";
import SellerUserRegistration from "./components/SellerUserRegistration";
import Forbidden from "./buyerModule/Forbidden";

function App() {
  function sensitiveFunctionDontEdit() {
    const expTime = 60 * 60 * 1000;
    let timer = null;

    function resetTimer() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        sessionStorage.removeItem("persistantState");
        window.location.reload(false);
      }, expTime);
    }
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);
    window.addEventListener("touchmove", resetTimer);
    window.addEventListener("touchstart", resetTimer);
    window.addEventListener("touchcancel", resetTimer);
    window.addEventListener("touchend", resetTimer);
    resetTimer();
  }
  useEffect(sensitiveFunctionDontEdit, []);

  const status = useSelector((state) => state.loginstatereducer);
  const user = status;
  const statusforbuyer = useSelector((state) => state.loginstateredbuyer);
  const Protecc = ({ children }) => {
    return user ? children : <Navigate to="/" />;
  };
  const ProteccBuyer = ({ children }) => {
    return statusforbuyer ? children : <Navigate to="/" />;
  };
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/*" element={<NotFound />} />
          <Route path="/" element={<Login />} />
          {/* <Route path="/authfailed" element={<Forbidden />} /> */}
          <Route path="/registerseller" element={<SellerRegistration />} />
          <Route
            path="/registerselleruser"
            element={
              <Protecc>
                <SellerUserRegistration />
              </Protecc>
            }
          />
          <Route path="/Registerbuyer" element={<RegisterBuyer />} />
          <Route
            path="/Registerbuyeruser"
            element={
              <ProteccBuyer>
                <RegisterBuyerUser />
              </ProteccBuyer>
            }
          />
          <Route
            path="/Listofbids"
            element={
              <Protecc>
                <Listofbids />
              </Protecc>
            }
          />
          <Route
            path="/OnlineClients"
            element={
              <Protecc>
                <OnlineClient />
              </Protecc>
            }
          />
          <Route path="/ForgotPwd" element={<ForgotPwd />} />
          <Route
            path="/WonLost"
            element={
              <Protecc>
                <WonLost />
              </Protecc>
            }
          />
          <Route
            path="/BidDetails"
            element={
              <Protecc>
                <BidDetails />
              </Protecc>
            }
          />
          <Route
            path="/UnapprovedBids"
            element={
              <Protecc>
                <UnapprovedBids />
              </Protecc>
            }
          />
          <Route
            path="/ApprovedBids"
            element={
              <Protecc>
                <ApprovedBids />
              </Protecc>
            }
          />
          <Route
            path="/updatesellerbid"
            element={
              <Protecc>
                <UpdateSellerBid />
              </Protecc>
            }
          />
          <Route
            path="/buyerhomepage"
            element={
              <ProteccBuyer>
                <Buyerhomepage />
              </ProteccBuyer>
            }
          />
          <Route
            path="/myquotations"
            element={
              <ProteccBuyer>
                <MyQuotations />
              </ProteccBuyer>
            }
          />
          <Route
            path="/listbuyerusers"
            element={
              <ProteccBuyer>
                <BuyerUsers />
              </ProteccBuyer>
            }
          />
          <Route
            path="/detailsofbid"
            element={
              <ProteccBuyer>
                <DetailsOfBid />
              </ProteccBuyer>
            }
          />
          <Route
            path="/updatequotation"
            element={
              <ProteccBuyer>
                <UpdateQuotation />
              </ProteccBuyer>
            }
          />
          <Route
            path="/newquotation"
            element={
              <ProteccBuyer>
                <NewQuotation />
              </ProteccBuyer>
            }
          />
          <Route
            path="/buyerinventory"
            element={
              <ProteccBuyer>
                <BuyerInventory />
              </ProteccBuyer>
            }
          />
          <Route
            path="/buyerinventoryadditem"
            element={
              <ProteccBuyer>
                <BuyerInventoryAddItem />
              </ProteccBuyer>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
