import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const ForgotPwd = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div style={{ padding: "240px 0px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <h3>Please contact the administrator!</h3>
        </div>
        <br></br>
        <div>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
            }}
          >
            <button className="sign" onClick={()=>{navigate("/")}}>Home Page</button>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPwd;
