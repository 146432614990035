import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Loading from "./Loading";
import JwtRequestHandler from "../utils/JwtRequestHandler";

function BidDetails() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const myState = useSelector((state) => state.loginreducer);
  const [datas, setDatas] = useState([]);
  const navigate = useNavigate();
  const bidId = useSelector((state) => state.bidreducer);
  const [isLoading, setIsLoading] = useState(false);
  const [showCalc, setShowCalc] = useState(false);
  const [msg, setMsg] = useState("");
  const [validator, setValidator] = useState();
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");

  function Biddet() {
    const idBid = bidId;
    // const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/biddet`,
        {
          idBid: idBid,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        const processedData = processData(response.data);
        setDatas(processedData);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });
    // return () => {
    //   source.cancel()
    // }
  }

  useEffect(Biddet, []);

  const processData = (data) => {
    const processedArray = data.map((item) => ({
      ITEMCODE: item.OT_RFQ_ITEM_CODE,
      ITEMNAME: item.OT_RFQ_ITEM_NAME,
      RFQID: item.OT_RFQ_ID,
      QUANTITY: item.OT_RFQ_ITEM_QTY_REQUIRED,
      DESC: item.OT_RFQ_ITEM_TECHNICAL_SPECS,
      EMAILID: myState[0].email,
      SUPPLIERID: myState[0].id,
      UPDATEDBY: myState[0].username,
      VATPERC: item.VATPERC,
      IMG: item.OT_RFQ_IMAGE_REQUIRED,
      CHARGEDAMT: 0,
      VATAMT: 0,
      CHARGEDAMTINCLUSIVE: 0,
    }));
    return processedArray;
  };

  function calcAmount(array) {
    const amount = array.reduce(
      (total, current) => Number(total) + Number(current.CHARGEDAMT),
      0
    );
    return amount;
  }

  function calcVatamt(array) {
    const vatamt = array.reduce(
      (total, current) => Number(total) + Number(current.VATAMT),
      0
    );
    return vatamt;
  }

  function calcGrandTotal(array) {
    const grandtot = array.reduce(
      (total, current) => Number(total) + Number(current.CHARGEDAMTINCLUSIVE),
      0
    );
    return grandtot;
  }

  const handleFinalSubmit = (e) => {
    if (validator === 1) {
      alert(
        "Can not apply. You have already applied for this bid. Please use the Update method to update values."
      );
    } else {
      let empty = false;
      for (var i = 0; i < datas.length; i++) {
        if (
          datas[i].CHARGEDAMT == undefined ||
          datas[i].CHARGEDAMT == "" ||
          datas[i].CHARGEDAMT == null ||
          datas[i].CHARGEDAMT == 0
        ) {
          empty = true;
          break;
        }
      }
      if (empty) {
        alert("Please fill in all the values before saving.");
      } else {
        let error = false;
        for (var i = 0; i < datas.length; i++) {
          setIsLoading(true);
          axios
            .post(
              `${BASEURL}/ratepost`,
              { data: datas[i] },
              {
                headers: {
                  "auth-token": authToken,
                },
              }
            )
            .then((response) => {
              console.log(response.data.message);
            })
            .catch((err) => {
              error = true;
              console.log(err);
            });
        }
        setIsLoading(false);
        if (!error) {
          alert("Bid Saved and Applied Successfully!");
          navigate("/unapprovedbids");
        } else {
          alert("An error occured on the server-side. Please try again.");
        }
      }
    }
  };

  function getMsg() {
    const id = myState[0].email;
    // const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/whapplied`,
        {
          id: id,
          rfqid: bidId,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        if (response.data.val === 1) {
          setValidator(response.data.val);
          setMsg("You have already applied for the bid.");
        } else if (response.data.val === 2) {
          setValidator(response.data.val);
          setMsg("You have Not applied for this bid!");
        } else if (response.data.val === 3) {
          setValidator(response.data.val);
          setMsg("Trouble retrieving the message. Please refresh the page.");
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(getMsg, []);

  const renderBidDetail = (
    <div>
      <Navbar />
      <div style={{ position: "relative", top: "55px" }}>
        <div className="header"> QUOTATION DETAILS </div>
        <span
          style={{
            fontFamily: "cursive",
            fontSize: "18px",
            paddingLeft: "8px",
          }}
        >
          Message: {msg}
        </span>
        <div className="container">
          <table>
            <thead>
              <tr className="headerName">
                <th style={{ textAlign: "center" }}>BID ID</th>
                <th style={{ width: "100px", textAlign: "center" }}>
                  Item Code
                </th>
                <th style={{ width: "100px", textAlign: "center" }}>
                  Item Name
                </th>
                <th style={{ width: "100px", textAlign: "center" }}>
                  Qty Requried
                </th>
                <th style={{ width: "100px", textAlign: "center" }}>
                  Description
                </th>
                <th style={{ width: "90px", textAlign: "center" }}>
                  Image Required
                </th>
                <th style={{ textAlign: "center" }}>VAT%</th>
                <th style={{ textAlign: "center" }}>Rate (per Item)</th>
                <th style={{ textAlign: "center" }}>Total Rate Charged</th>
                <th style={{ textAlign: "center" }}>Total VAT Amount</th>
                <th style={{ textAlign: "center" }}>Total Amount(inc VAT)</th>
              </tr>
            </thead>
            <tbody className="Info">
              {datas.map((data) => (
                <tr className="table-row" key={data.ITEMCODE}>
                  <td style={{ textAlign: "center" }}>{data.RFQID}</td>
                  <td style={{ textAlign: "center" }}>{data.ITEMCODE}</td>
                  <td style={{ textAlign: "center" }}>{data.ITEMNAME}</td>
                  <td style={{ textAlign: "center" }}>{data.QUANTITY}</td>
                  <td style={{ textAlign: "center" }}>{data.DESC}</td>
                  <td className="tbody">{data.IMG ? "Yes" : "No"}</td>
                  <td style={{ textAlign: "center" }}>
                    {data.VATPERC.toFixed(2)}
                  </td>
                  <td style={{ width: "180px", textAlign: "right" }}>
                    <input
                      className="rate_value"
                      type="number"
                      name="CHARGEDAMT"
                      min={1}
                      placeholder="Insert Rate Here"
                      onChange={(e) => {
                        setShowCalc(!showCalc);
                        data.CHARGEDAMT = e.target.value * data.QUANTITY;
                        data.CHARGEDAMTINCLUSIVE =
                          Number(e.target.value) * Number(data.QUANTITY) +
                          (Number(data.VATPERC) / 100) *
                            (Number(e.target.value) * Number(data.QUANTITY));
                        data.VATAMT =
                          (Number(data.VATPERC) / 100) *
                          (Number(e.target.value) * Number(data.QUANTITY));
                      }}
                    ></input>
                  </td>
                  <td style={{ textAlign: "right" }}>{data.CHARGEDAMT}</td>
                  <td style={{ textAlign: "right" }}>
                    {data.VATAMT.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td style={{ textAlign: "right", padding: "0.8% " }}>
                    {data.CHARGEDAMTINCLUSIVE.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <main className="main_bid_price">
            <div className="VAT_D">
              <label className="value">Total Amount Charged :</label>
              <input
                className="Vat-AMT"
                type="text"
                disabled={true}
                value={calcAmount(datas).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                style={{ color: "black" }}
              />
            </div>
            <div className="VAT_D">
              <label className="value">Total VAT Amount :</label>
              <input
                className="Vat-AMTT1"
                type="text"
                disabled={true}
                value={calcVatamt(datas).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                style={{ color: "black" }}
              />
            </div>
            <div className="VAT_De">
              <label className="value">Grand Total :</label>
              <input
                className="Vat-AMTT"
                type="text"
                disabled={true}
                value={calcGrandTotal(datas).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                style={{ color: "black" }}
              />

              <button
                type="submit"
                className="apply_bid"
                onClick={(e) => {
                  const confirmBox = window.confirm(
                    "Are you sure you want to apply?"
                  );
                  if (confirmBox === true) {
                    handleFinalSubmit(e);
                  }
                }}
              >
                Save & Apply
              </button>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderBidDetail}</div>;
}

export default BidDetails;
