const initialState = [];
const loginreducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return action.data;
    case "REFRESH":
      return state.map((login)=>({
        ...login,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
      }))
    default:
      return state;
  }
};
export default loginreducer;