import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../CSS/RegisterbuyerUser.css";
import BuyerNavbar from "./BuyerNavbar";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";
const RegisterBuyerUser = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [buyerusername, setBuyerusername] = useState(null);
  const [password, setPassword] = useState("");
  const [confpass, setConfpass] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showpass, setShowpass] = useState(false);
  const mystate = useSelector((state) => state.loginreducer);
  // const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleUser = (e) => {
    setBuyerusername(e.target.value);
  };
  const handlePass = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirm = (e) => {
    setConfpass(e.target.value);
  };
  function handleRegister(e) {
    e.preventDefault();
    const userid = mystate[0].id;
    const createdby = mystate[0].createdby;
    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
    if (!emailRegex.test(email)) {
      setErrMsg("Please enter a valid email address!");
    } else if (!/[a-z]/.test(password)) {
      setErrMsg("Password must have atleast one small letter.");
    } else if (!/[A-Z]/.test(password)) {
      setErrMsg("Password must have one CAPS letter.");
    } else if (!/\d/.test(password)) {
      setErrMsg("Password must have one number.");
    } else if (!/[^a-zA-Z0-9]/.test(password)) {
      setErrMsg("Password must have a special character.");
    } else if (!/^.{8,24}$/.test(password)) {
      setErrMsg("Password should be 8-24 characters long.");
    } else if (password === confpass) {
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/registerbuyeruser`,
          {
            buyerusername,
            email,
            password,
            createdby,
            userid,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          if (response.data.message === "exist") {
            setErrMsg("Account already exists! Please use a different email!");
          } else if (response.data.message === "ok") {
            alert("Registration Successful!");
            navigate("/");
          }
        });
    } else {
      setErrMsg("Passwords don't match! Please try again.");
    }
  }
  useEffect(() => {
    setErrMsg("");
  }, [email, password, confpass]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="color">
      <BuyerNavbar />

      <div
        style={{
          position: "relative",
          top: "55px",
        }}
      >
        <div className="forma">
          <div className="bg">
            <div>
              {/* <div className="head">
                <div className="heading1"> REGISTRATION PAGE FOR BUYER USERS</div>
              </div> */}
              {/* <div class="ribbon">
                <div class="wrap">
                  <span class="ribbon6">Скидка 10%</span>
                </div>
              </div> */}
              <p
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <div class="ribbon">
                <span class="ribbon5">REGISTRATION PAGE FOR BUYER USERS</span>
              </div>
              <form
                onSubmit={(e) => {
                  const confirmBox = window.confirm(
                    "Are you sure you want to submit?"
                  );
                  if (confirmBox === true) {
                    handleRegister(e);
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                <div className="line_spacing">
                  <div>
                    <div>
                      {" "}
                      <label>Name</label>
                    </div>
                    <div>
                      <input
                        className="input_field"
                        id="name"
                        type="text"
                        onChange={handleUser}
                        required
                      />
                    </div>
                    {/* <div>
                      <label className="label4" htmlFor="email">
                        Email:
                      </label>
                    </div> */}
                    <label> Email</label>
                    <div>
                      <input
                        className="input_field"
                        id="email"
                        type="email"
                        onChange={handleEmail}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    {/* <div>
                      <label className="label3" htmlFor="password">
                        Password:
                      </label>
                    </div> */}
                    <label>Password</label>
                    <div>
                      <input
                        className="input_field"
                        id="password"
                        type={showpass ? "text" : "password"}
                        onChange={handlePass}
                        autoComplete="new-password"
                        required
                      />
                    </div>
                    {/* 
                    <div>
                      <label className="label1" htmlFor="confirm">
                        Confirm Password:
                      </label>
                    </div> */}
                    <label>Confirm Password</label>
                    <div>
                      <input
                        className="input_field"
                        id="confirm"
                        type={showpass ? "text" : "password"}
                        onChange={handleConfirm}
                        required
                      />
                    </div>
                  </div>
                  <div className="left_align">
                    <input
                      style={{ marginLeft: "4px" }}
                      type="checkbox"
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    ></input>

                    <span style={{ marginLeft: "6px" }}>Show Password</span>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <button className="register1">Create User</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterBuyerUser;
