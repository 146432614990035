import React, { useEffect, useState } from "react";
import BuyerNavbar from "./BuyerNavbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "../CSS/NewQuotation.css";
import Footer from "../components/Footer";
import "../CSS/NotFound.css";
import Select from "react-select";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";

function NewQuotation() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const buyerdetails = useSelector((state) => state.loginreducer);
  const client = buyerdetails[0].id;
  const processedby = buyerdetails[0].username;
  const [valuesRefresher, setValRef] = useState(false);
  const [datas, setDatas] = useState([]);
  const navigate = useNavigate();
  const [expiry, setExpiry] = useState();
  const [narration, setNarration] = useState("");
  const [activedays, setActivedays] = useState();
  const [itemTableData, setItemTableData] = useState([]);
  const [itemCodeInventory, setItemCodeInventory] = useState([]);
  // const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");

  function resetItemTable() {
    // const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/iteminventorydata`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setItemCodeInventory(response.data);
        setItemTableData({
          client: client,
          itemcode: "",
          itemname: "",
          quantity: null,
          uom: "",
          budget: null,
          techspecs: "",
          imgreq: null,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        //  else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(resetItemTable, []);

  function calculateDays(e) {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    const timeDiff = selectedDate.getTime() - currentDate.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    setActivedays(dayDiff);
    setExpiry(moment(selectedDate).format("DD/MM/YYYY"));
  }

  const handleItemInput = (e) => {
    const { name, value } = e.target;
    setItemTableData({ ...itemTableData, [name]: value });
  };

  const handleCodeInput = (e) => {
    const selectedValue = e.value;
    const selectedOption = itemCodeInventory.find(
      (obj) => obj.ITEMCODE === selectedValue
    );
    setItemTableData(
      selectedOption
        ? {
            ...itemTableData,
            itemname: selectedOption.ITEMGENERICNAME,
            itemcode: selectedValue,
            uom: selectedOption.BASEUOM,
            techspecs: selectedOption.ITEMDESCRIPTION,
          }
        : {
            ...itemTableData,
            itemname: "",
            itemcode: "",
            uom: "",
            techspecs: "",
          }
    );
  };

  const handleNameInput = (e) => {
    const selectedValue = e.value;
    const selectedOption = itemCodeInventory.find(
      (obj) => obj.ITEMGENERICNAME === selectedValue
    );
    setItemTableData(
      selectedOption
        ? {
            ...itemTableData,
            itemname: selectedValue,
            itemcode: selectedOption.ITEMCODE,
            uom: selectedOption.BASEUOM,
            techspecs: selectedOption.ITEMDESCRIPTION,
          }
        : {
            ...itemTableData,
            itemname: "",
            itemcode: "",
            uom: "",
            techspecs: "",
          }
    );
  };

  const handleAddRow = (e) => {
    if (
      itemTableData.itemcode == "" ||
      itemTableData.itemname == "" ||
      itemTableData.quantity == null ||
      itemTableData.quantity == 0 ||
      itemTableData.uom == "" ||
      itemTableData.budget == null ||
      itemTableData.budget == 0 ||
      itemTableData.techspecs == ""
    ) {
      alert(
        "Please fill valid values in all inputs provided before adding an item."
      );
    } else {
      datas.push(itemTableData);
      setItemTableData({
        client: client,
        itemcode: "",
        itemname: "",
        quantity: null,
        uom: "",
        budget: null,
        techspecs: "",
        imgreq: null,
      });
      document.getElementById("itemcodeinput").value = "Select";
      document.getElementById("itemnameinput").value = "Select";
      document.getElementById("quantityinput").value = "";
      document.getElementById("uominput").value = "Select";
      document.getElementById("budgetinput").value = "";
      document.getElementById("techinput").value = "";
      document.getElementById("imgreqinput").value = "Select";
    }
  };

  const handleDeleteRow = (e, index) => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this row?"
    );
    if (confirmBox === true) {
      e.preventDefault();
      const newData = [...datas];
      newData.splice(index, 1);
      setDatas(newData);
    } else {
      e.preventDefault();
    }
  };

  function handleSubmit() {
    if (expiry == "" || expiry == null || narration == "") {
      alert(
        "Cannot save without header details. Please fill in header details"
      );
    } else {
      let error = false;
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/saveheader`,
          {
            client,
            expiry,
            activedays,
            narration,
            processedby,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          const rfqid = response.data.rfqid;
          for (var i = 0; i < datas.length; i++) {
            axios
              .post(
                `${BASEURL}/saveitems`,
                { rfqid: rfqid, data: datas[i] },
                {
                  headers: {
                    "auth-token": authToken,
                  },
                }
              )
              .then((response) => {
                console.log(response.data.message);
              })
              .catch((err) => {
                error = true;
              });
          }
          setIsLoading(false);
          if (!error) {
            alert("RFQ has been saved successfully.");
            navigate("/myquotations");
          } else {
            alert("An Error Occured on the server. Please try again.");
          }
        });
    }
  }

  function calcTotalBudget(array) {
    const totalBudget = array
      .reduce((total, current) => Number(total) + Number(current.budget), 0)
      .toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    return totalBudget;
  }

  // styling given react-select
  const styleforselect = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid ",
      height: "10px",
    }),
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <BuyerNavbar />
      </div>
      <div style={{ position: "relative", top: "55px" }}>
        <div>
          <form>
            <div className="boxshadow">
              <h3>Request for Quotation</h3>
              <div className="bo">
                <div className="Req_for">
                  <div className="form__group field">
                    <p style={{ width: "117px" }}>Buyer ID</p>

                    <input
                      className="effect"
                      type="text"
                      placeholder="Placeholder Text"
                      disabled
                      value={client}
                    />
                    <span className="focus-border"></span>
                  </div>
                  <div className="Requ">
                    <p className="Date_of_creation">Date of Creation</p>

                    <input
                      className="effect_8"
                      type="text"
                      disabled
                      value={moment(new Date()).format("DD-MM-YYYY")}
                    ></input>
                  </div>
                </div>
                <div className="Req_for">
                  <div>
                    <p>Expiry Date</p>

                    <input
                      style={{ width: "160px " }}
                      className="effect"
                      type="date"
                      required
                      min={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        calculateDays(e);
                      }}
                    ></input>
                  </div>

                  <div className="form__group field">
                    <p>Active Days</p>

                    <input
                      className="effect"
                      type="number"
                      disabled
                      value={activedays}
                    ></input>
                  </div>
                </div>
                <div className="Req_for">
                  <div className="form__group_field1">
                    <p className="Proceed_by" style={{ width: "93px" }}>
                      Processed By
                    </p>

                    <input
                      className="effect"
                      style={{ color: "black" }}
                      type="text"
                      disabled
                      value={processedby}
                    ></input>
                  </div>
                  <div className="col">
                    <p className="Narration_req">Narration</p>

                    <textarea
                      style={{ width: "100%" }}
                      class="effect"
                      className="textarea"
                      type="text"
                      name="narration"
                      required
                      onChange={(e) => {
                        setNarration(e.target.value);
                      }}
                      placeholder="Type Narration.."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-shadow1">
              <h3>Items Required</h3>
              <br></br>
              <div className="Request1">
                <div className="form__group field">
                  <p className="Item_code">Item Code</p>
                  <div class="col-3">
                    {/* <select
                                            class="Itemcode"
                                            className="effect-1 "
                                            style={{ width: "170px" }}
                                            id='itemcodeinput'
                                            name='itemcode'
                                            value={itemTableData.itemcode}
                                            onChange={(e) => { handleCodeInput(e) }}
                                        >
                                            <option value={''}>Select</option>
                                            {itemCodeInventory.map((item) => (
                                                <option value={item.ITEMCODE}>{item.ITEMCODE}</option>
                                            ))}
                                        </select> */}
                    <Select
                      // className='Itemcode'
                      id="itemcodeinput"
                      name="itemcode"
                      styles={styleforselect}
                      value={
                        itemTableData.itemcode
                          ? {
                              label: itemTableData.itemcode,
                              value: itemTableData.itemcode,
                            }
                          : { label: "Select..", value: "" }
                      }
                      onChange={(e) => {
                        handleCodeInput(e);
                      }}
                      required
                      options={itemCodeInventory.map((item) => ({
                        label: item.ITEMCODE,
                        value: item.ITEMCODE,
                      }))}
                    />
                    <span class="focus-border"></span>
                  </div>
                </div>
                <div className="form__group field">
                  <p className="Name">Name</p>
                  <div className="col-3">
                    {/* <select

                                            className="effect-1"
                                            id='itemnameinput'
                                            name='itemname'
                                            required
                                            class="Name_required"
                                            style={{ height: "25px", }}
                                            value={itemTableData.itemname}
                                            onChange={(e) => { handleNameInput(e) }}

                                        >
                                            <option value={''}>Select</option>
                                            {itemCodeInventory.map((item) => (
                                                <option value={item.ITEMGENERICNAME}>{item.ITEMGENERICNAME}</option>
                                            ))}
                                        </select> */}
                    <Select
                      className="Name_required"
                      styles={styleforselect}
                      id="itemnameinput"
                      name="itemname"
                      required
                      onChange={(e) => {
                        handleNameInput(e);
                      }}
                      value={
                        itemTableData.itemname
                          ? {
                              label: itemTableData.itemname,
                              value: itemTableData.itemname,
                            }
                          : { label: "Select..", value: "" }
                      }
                      options={itemCodeInventory.map((item) => ({
                        label: item.ITEMGENERICNAME,
                        value: item.ITEMGENERICNAME,
                      }))}
                    />
                    <span className="focus-border"></span>
                  </div>
                </div>
              </div>
              <div className="Request1">
                <div className="form__group field">
                  <p>Quantity</p>
                  <div className="col-3">
                    <input
                      className="effect-1"
                      id="quantityinput"
                      name="quantity"
                      // required
                      type="number"
                      placeholder="Qty Required.."
                      min={1}
                      onChange={(e) => {
                        handleItemInput(e);
                      }}
                    ></input>
                    <span class="focus-border"></span>
                  </div>
                </div>
                <div className="form__group field">
                  <p className="Name">UOM</p>
                  <div className="col-3">
                    <input
                      type="text"
                      className="effect-1"
                      style={{ width: "170px" }}
                      id="uominput"
                      name="uom"
                      placeholder="Unit of Measurement"
                      // required
                      value={itemTableData.uom}
                    ></input>
                    <span class="focus-border"></span>
                  </div>
                </div>
                <div className="form__group field">
                  <p className="Name1">Budget</p>
                  <div className="col-3">
                    <input
                      className="effect-1"
                      id="budgetinput"
                      name="budget"
                      type="number"
                      min={1}
                      // required
                      placeholder="Rate Expected.."
                      onChange={(e) => {
                        handleItemInput(e);
                      }}
                    ></input>
                    <span class="focus-border"></span>
                  </div>
                </div>
                <div className="form__group field">
                  <p className="Name2">Request Images</p>
                  <div className="col-3">
                    <select
                      className="effect-1"
                      style={{ width: "170px" }}
                      id="imgreqinput"
                      // required
                      name="imgreq"
                      onChange={(e) => {
                        handleItemInput(e);
                      }}
                    >
                      <option disabled hidden selected>
                        Select
                      </option>
                      <option value={Number(1)}>Yes</option>
                      <option value={Number(0)}>No</option>
                    </select>
                    <span class="focus-border"></span>
                  </div>
                </div>
              </div>
              {/* <div className='Request1'> */}

              <div className="tech2" style={{ display: "flex" }}>
                <p>Technical Specifications</p>
                <div className="col-3">
                  <input
                    className="effect-1"
                    // class="textarea"
                    id="techinput"
                    style={{ width: "383%" }}
                    name="techspecs"
                    // required
                    type="text"
                    defaultValue={itemTableData.techspecs}
                    onChange={(e) => {
                      handleItemInput(e);
                    }}
                    placeholder="Specifications.."
                  ></input>
                  <span class="focus-border"></span>
                </div>
              </div>
              {/* </div> */}
            </div>
            <button
              className="log_btn3"
              type="button"
              style={{ zIndex: 999 }}
              onClick={(e) => {
                const confirmBox = window.confirm(
                  "Are you sure you want to add this item?"
                );
                if (confirmBox === true) {
                  handleAddRow();
                } else {
                  e.preventDefault();
                }
              }}
            >
              Add Item
            </button>
          </form>
        </div>
        <br></br>
        <div
          className="div_height"
          style={
            datas.length > 0
              ? { visibility: "visible" }
              : { visibility: "hidden" }
          }
        >
          <h3 style={{ marginLeft: "19px" }}>Items Added</h3>
          <div className="Tab">
            <table className="table">
              <thead>
                <tr className="tr">
                  <th className="th">Item Code</th>
                  <th className="th">Item Name</th>
                  <th className="th">Quantity</th>
                  <th className="th">UOM</th>
                  <th className="th">Budget</th>
                  <th className="th">Technical Specs</th>
                  <th className="th">Image Required</th>
                  <th className="th">Actions</th>
                </tr>
              </thead>
              <tbody>
                {datas.map((data, index) => (
                  <tr key={data.itemcode}>
                    <td className="td">
                      <input
                        className="input"
                        type="text"
                        name="itemcode"
                        value={data.itemcode}
                        placeholder="Insert Item code"
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        type="text"
                        name="itemname"
                        placeholder="Insert Item name"
                        value={data.itemname}
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        type="number"
                        name="quantity"
                        min={1}
                        placeholder="Insert quantity"
                        defaultValue={data.quantity}
                        onChange={(e) => {
                          data.quantity = e.target.value;
                        }}
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        value={data.uom}
                        name="uom"
                      ></input>
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        name="budget"
                        value={
                          data.budget !== null
                            ? parseFloat(
                                data.budget.replace(/,/g, "")
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                            : ""
                        }
                        placeholder="Insert budget"
                        onChange={(e) => {
                          setValRef(!valuesRefresher);
                          // Only update the data.budget if the input value is a valid number (with optional commas).
                          const inputValue = e.target.value.replace(
                            /[^0-9,.]/g,
                            ""
                          ); // Keep only digits, commas, and periods.
                          const numericValue = parseFloat(
                            inputValue.replace(/,/g, "")
                          );
                          if (!isNaN(numericValue)) {
                            data.budget = numericValue;
                          }
                        }}
                      />
                    </td>
                    <td className="td">
                      <input
                        className="input"
                        type="text"
                        name="techspecs"
                        placeholder="Insert tech specs"
                        value={data.techspecs}
                      />
                    </td>
                    <td className="td">
                      <select
                        className="input"
                        style={{ width: "130px" }}
                        required
                        name="imgreq"
                        defaultValue={data.imgreq}
                        onChange={(e) => {
                          data.imgreq = e.target.value;
                        }}
                      >
                        <option disabled hidden selected>
                          Select
                        </option>
                        <option value={Number(1)}>Yes</option>
                        <option value={Number(0)}>No</option>
                      </select>
                    </td>
                    <td className="td">
                      <button
                        className="btnd"
                        onClick={(e) => {
                          handleDeleteRow(e, index);
                        }}
                      >
                        Delete Row
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              <div className="total_budget">
                <h3>Total Items</h3>
                <input
                  style={{
                    color: "black",
                    marginLeft: "33px",
                    height: "30px",
                    background: "transparent",
                    border: "none",
                    borderBottom: "1.5px solid #565050",
                    marginLeft: " 46px",
                  }}
                  type="number"
                  value={datas.length}
                  disabled
                ></input>
              </div>
              <div className="total_budget">
                <h3>Total Budget</h3>
                <input
                  style={{
                    color: "black",
                    marginLeft: "17px",
                    height: "30px",
                    border: "none",
                    borderBottom: "1.5px solid #565050",
                    marginLeft: " 30px",
                  }}
                  value={calcTotalBudget(datas)}
                  disabled
                ></input>
              </div>
              <div>
                <button
                  className="log_btn3"
                  onClick={(e) => {
                    e.preventDefault();
                    const confirmBox = window.confirm(
                      "Are you sure you want to submit?"
                    );
                    if (confirmBox === true) {
                      handleSubmit(e);
                    } else {
                      e.preventDefault();
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default NewQuotation;
