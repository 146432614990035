import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import "../CSS/BuyerInventory.css";
import BuyerNavbar from "./BuyerNavbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";

function BuyerInventoryAddItem() {
  const [itemName, setItemName] = useState("");
  const [desc, setDesc] = useState("");
  const [classification, setClassification] = useState("");
  const [category, setCategory] = useState("");
  const [subCat, setSubCat] = useState("");
  const [isVatable, setIsVatable] = useState();
  const [baseUOM, setBaseUOM] = useState("");
  const [altUOM, setAltUOM] = useState("");
  const [convFactor, setConvFactor] = useState();
  const authToken = window.sessionStorage.getItem("auth-token");
  //backend options:
  const [catsBack, setCatsback] = useState([]);
  const [subcatBack, setSubCatsback] = useState([]);
  const [classBack, setClassBack] = useState([]);
  const [uomBack, setUOMBack] = useState([]);
  const [altuomBack, setAltUOMBack] = useState([]);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.loginreducer);
  const BASEURL = process.env.REACT_APP_BASEURL;
  // const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);

  function itemOptions() {
    // const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/inventorycategory`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setCatsback(response.data);
        axios
          .get(`${BASEURL}/inventorysubcategory`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setSubCatsback(response.data);
            axios
              .get(`${BASEURL}/inventoryclassification`, {
                headers: {
                  "auth-token": authToken,
                },
              })
              .then((response) => {
                setClassBack(response.data);
                axios
                  .get(`${BASEURL}/inventorybaseuom`, {
                    headers: {
                      "auth-token": authToken,
                    },
                  })
                  .then((response) => {
                    setUOMBack(response.data);
                    axios
                      .get(`${BASEURL}/inventoryaltuom`, {
                        headers: {
                          "auth-token": authToken,
                        },
                      })
                      .then((response) => {
                        setAltUOMBack(response.data);
                        setIsLoading(false);
                      })
                      .catch((err) => {
                        if (axios.isCancel(err)) {
                          console.log("Request Cancelled.");
                        }
                        //  else if (axios.AxiosError) {
                        //   navigate("/authfailed");
                        // }
                      });
                  })
                  .catch((err) => {
                    if (axios.isCancel(err)) {
                      console.log("Request Cancelled.");
                    }
                    //  else if (axios.AxiosError) {
                    //   navigate("/authfailed");
                    // }
                  });
              })
              .catch((err) => {
                if (axios.isCancel(err)) {
                  console.log("Request Cancelled.");
                }
                // else if (axios.AxiosError) {
                //   navigate("/authfailed");
                // }
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Request Cancelled.");
            }
            // else if (axios.AxiosError) {
            //   navigate("/authfailed");
            // }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });

    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(itemOptions, []);

  function saveItem(e) {
    if (
      itemName == "" ||
      desc == "" ||
      classification == "none" ||
      category == "none" ||
      subCat == "none" ||
      baseUOM == "none" ||
      altUOM == "none" ||
      isVatable == "none" ||
      convFactor == ""
    ) {
      e.preventDefault();
      alert("Please Fill in all the values.");
    } else {
      e.preventDefault();
      setIsLoading(true);
      const createdby = userDetails[0].username;
      const curdate = new Date();
      const createdDate = moment(curdate).format("DD/MM/YYYY");
      axios
        .post(
          `${BASEURL}/savetoinventory`,
          {
            data: {
              itemName,
              desc,
              classification,
              category,
              subCat,
              isVatable,
              baseUOM,
              createdby,
              altUOM,
              convFactor,
              createdDate,
            },
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setIsLoading(false);
          alert(response.data.message);
          navigate("/buyerinventory");
        });
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <BuyerNavbar />
      <div style={{ position: "relative", top: "55px" }}>
        <div className="header">Add New Item to Inventory</div>
        <form className="form1">
          <div className="Item_Name">
            <div className="Addition_of_item">
              <div className="BuyerInventory">
                <p>Item Name</p>
                <input
                  required
                  className="border2"
                  type="text"
                  placeholder="Insert Item Name.."
                  onChange={(e) => {
                    setItemName(e.target.value);
                  }}
                ></input>
              </div>
              <div className="BuyerInventory">
                <p style={{ width: "133px" }}>Item Description</p>
                <input
                  style={{ width: "500px" }}
                  required
                  className="border2"
                  type="text"
                  placeholder="Item Desc..."
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="Addition_of_item">
              <div className="BuyerInventory">
                <p className="Vatable">Vatable</p>
                <select
                  className="dropdown"
                  required
                  onChange={(e) => setIsVatable(e.target.value)}
                >
                  <option value="none">Select</option>
                  <option value={Number(1)}>Yes</option>
                  <option value={Number(0)}>No</option>
                </select>
              </div>
              <div className="BuyerInventory">
                <p className="Convfac">Conversion Factor</p>
                <input
                  min={0}
                  className="dropdown"
                  required
                  type="number"
                  placeholder="Insert Conversion Factor"
                  onChange={(e) => {
                    setConvFactor(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="Addition_of_item_classi">
              <div className="BuyerInventory">
                <p>Classification</p>
                <select
                  style={{ marginLeft: "-11px" }}
                  className="dropdown"
                  required
                  onChange={(e) => setClassification(e.target.value)}
                >
                  <option value={"none"}>Select</option>
                  {classBack.map((item) => (
                    <option
                      key={item.CLASSIFICATION}
                      value={item.CLASSIFICATION}
                    >
                      {item.CLASSIFICATION}
                    </option>
                  ))}
                </select>
              </div>
              <div className="BuyerInventory">
                <p style={{ width: "130px" }}>Category</p>
                <select
                  className="dropdown"
                  required
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value={"none"}>Select</option>
                  {catsBack.map((item) => (
                    <option key={item.ITEMCATEGORY} value={item.ITEMCATEGORY}>
                      {item.ITEMCATEGORY}
                    </option>
                  ))}
                </select>
              </div>
              <div className="BuyerInventory">
                <p>Subcategory</p>
                <select
                  className="dropdown"
                  required
                  onChange={(e) => setSubCat(e.target.value)}
                >
                  <option value={"none"}>Select</option>
                  {subcatBack.map((item) => (
                    <option key={item.SUBCATEGORY} value={item.SUBCATEGORY}>
                      {item.SUBCATEGORY}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="Addition_of_item_uom">
              <div className="BuyerInventory">
                <p className="Buom"> Base Unit of Measurenment </p>
                <select
                  className="dropdown"
                  required
                  onChange={(e) => {
                    setBaseUOM(e.target.value);
                  }}
                >
                  <option value={"none"}>Select</option>
                  {uomBack.map((item) => (
                    <option value={item.BASEUOM}>{item.BASEUOM}</option>
                  ))}
                </select>
              </div>

              <div className="BuyerInventory">
                <p className="Auom">Alternative Unit of Measurenment</p>
                <select
                  className="dropdown"
                  required
                  onChange={(e) => {
                    setAltUOM(e.target.value);
                  }}
                >
                  <option value={"none"}>Select</option>
                  {altuomBack.map((item) => (
                    <option value={item.ALTUOM}>{item.ALTUOM}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="Save_Item">
            <button
              type="submit"
              className="SaveItem"
              onClick={(e) => {
                saveItem(e);
              }}
            >
              Save To Inventory
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default BuyerInventoryAddItem;
