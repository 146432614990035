import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../CSS/Register.css";
import Footer from "../components/Footer";
import { Country, City } from "country-state-city";
import Select from "react-select";

function SellerRegistration() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [buyercredlim, setBuyercredlim] = useState();
  const [buyercreddays, setBuyercreddays] = useState();
  const [buyertele, setBuyertele] = useState();
  const [password, setPassword] = useState("");
  const [confpass, setConfpass] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showpass, setShowpass] = useState(false);
  const [phoneCode, setPhoneCode] = useState();
  const [phoneBody, setPhoneBody] = useState();
  const authToken = window.sessionStorage.getItem("auth-token");

  const countries = Country.getAllCountries();
  const [cities, setCities] = useState([]);

  const handleCountry = (e) => {
    const selectedCountry = e.value;
    const cityValues = City.getCitiesOfCountry(selectedCountry.isoCode);
    setCities(cityValues);
    setCountry(selectedCountry.name);
  };
  function handleRegister(e) {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,24}$/;
    if (!emailRegex.test(email)) {
      setErrMsg("Please enter a valid email address!");
    } else if (!/[a-z]/.test(password)) {
      setErrMsg("Password must have atleast one small letter.");
    } else if (!/[A-Z]/.test(password)) {
      setErrMsg("Password must have one CAPS letter.");
    } else if (!/\d/.test(password)) {
      setErrMsg("Password must have one number.");
    } else if (!/[^a-zA-Z0-9]/.test(password)) {
      setErrMsg("Password must have a special character.");
    } else if (!/^.{8,24}$/.test(password)) {
      setErrMsg("Password should be 8-24 characters long.");
    } else if (password === confpass) {
      axios
        .post(
          `${BASEURL}/registerseller`,
          {
            user,
            email,
            password,
            country,
            city,
            address,
            buyertele,
            buyercredlim,
            buyercreddays,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          if (response.data.message === "exist") {
            setErrMsg("Account already exists! Please use a different email!");
          } else if (response.data.message === "ok") {
            alert("Registration Successful!");
            navigate("/");
          }
        });
    } else {
      setErrMsg("Passwords don't match!");
    }
  }
  useEffect(() => {
    setErrMsg("");
  }, [email, password, confpass]);
  const styleforselect = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid ",
      height: "10px",
      minHeight: "initial",
      height: "30px",
      width: "232px",
    }),
  };
  const styleforselecttelephone = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid ",
      height: "10px",
      minHeight: "initial",
      height: "30px",
      width: "90px",
    }),
  };

  return (
    <div>
      <div className="zab">
        <Link to="/" className="company_nam">
          ZABUNI
        </Link>
      </div>
      <div className="User_register">
        <div class=" main-center">
          <form
            onSubmit={(e) => {
              const confirmBox = window.confirm(
                "Are you sure you want to submit?"
              );
              if (confirmBox === true) {
                handleRegister(e);
              } else {
                e.preventDefault();
              }
            }}
          >
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

            <div class="ribbon">
              <span class="ribbon7">REGISTRATION PAGE FOR SUPPLIERS</span>
            </div>

            <div>
              <div className="display_block">
                <div className="display_flex">
                  <div>
                    <label>Company Name</label>
                    <div>
                      <input
                        className="form-group"
                        id="name"
                        type="text"
                        onChange={(e) => {
                          setUser(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    <label>Email</label>
                    <div>
                      <input
                        className="form-group"
                        id="email"
                        type="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="display_flex">
                  <div>
                    <label>Address</label>
                    <div>
                      <input
                        className="form-group"
                        id="address"
                        type="text"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    <label>Telephone</label>
                    <div style={{ display: "flex", gap: "4px" }}>
                      <div>
                        <Select
                          required
                          id="phonecode"
                          styles={styleforselecttelephone}
                          options={countries.map((item) => ({
                            label: item.phonecode,
                            value: item.phonecode,
                          }))}
                          onChange={(e) => {
                            setPhoneCode(e.value);
                            setBuyertele("" + e.value + phoneBody);
                          }}
                        />
                      </div>
                      <div>
                        <input
                          className="form-group"
                          style={{ width: "140px" }}
                          id="tele"
                          maxLength={15}
                          type="number"
                          onChange={(e) => {
                            setPhoneBody(e.target.value);
                            setBuyertele("" + phoneCode + e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="display_flex">
                  <div className="telephone">
                    <div style={{ gap: "5px" }}>
                      <span>Telephone</span>

                      <Select
                        required
                        id="phonecode"
                        styles={styleforselecttelephone}
                        options={countries.map((item) => ({
                          label: item.phonecode,
                          value: item.phonecode
                        }))}
                        onChange={(e) => { setPhoneCode(e.value) }}
                      /></div>
                    <div>
                      <input
                        className="form-group"
                        style={{ marginTop: "20px" }}
                        id="tele"
                        maxLength={15}
                        type="tel"
                        onChange={(e) => { setPhoneBody(e.target.value) }}
                        required
                      /></div>

                  </div>
                </div> */}
                <div className="display_flex">
                  <div>
                    <label>Credit Limit</label>
                    <div>
                      <input
                        className="form-group"
                        id="credlimit"
                        type="number"
                        onChange={(e) => {
                          setBuyercredlim(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    <label>Credit Days</label>
                    <div>
                      <input
                        className="form-group"
                        id="creddays"
                        type="number"
                        onChange={(e) => {
                          setBuyercreddays(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="display_flex">
                  <div>
                    <label>Country</label>
                    <div>
                      {/* <input
                        className="form-group"
                        id="country"

                        type="text"
                        onChange={handleCountry}
                        required
                      /> */}
                      <Select
                        id="country"
                        styles={styleforselect}
                        required
                        onChange={(e) => handleCountry(e)}
                        options={countries.map((item) => ({
                          label: item.name,
                          value: item,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="division">
                    <label>City</label>
                    <div>
                      {/* <input
                        className="form-group"
                        id="city"

                        type="text"
                        onChange={handleCity}
                        required
                      /> */}
                      <Select
                        id="city"
                        styles={styleforselect}
                        required
                        onChange={(e) => {
                          setCity(e.value);
                        }}
                        options={cities.map((item) => ({
                          label: item.name,
                          value: item.name,
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="display_flex">
                  <div>
                    <label>Password</label>
                    <div>
                      <input
                        className="form-group"
                        id="password"
                        type={showpass ? "text" : "password"}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        autoComplete="new-password"
                        required
                      />
                    </div>
                  </div>
                  <div className="division">
                    <div>
                      <label>confirm Password</label>
                      <div>
                        <input
                          className="form-group"
                          id="confirm"
                          type={showpass ? "text" : "password"}
                          onChange={(e) => {
                            setConfpass(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lower">
                  <div>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setShowpass(!showpass);
                      }}
                    ></input>

                    <span className="show_pass">Show Password</span>
                  </div>
                  <div>
                    <button className="register">Register</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SellerRegistration;
