import React, { useEffect, useState } from "react";
import BuyerNavbar from "./BuyerNavbar";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Footer from "../components/Footer";
import JwtRequestHandler from "../utils/JwtRequestHandler";
import Loading from "../components/Loading";

function DetailsOfBid() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const biddet = useSelector((state) => state.buyerbidreducer);
  const userDetails = useSelector((state) => state.loginreducer);
  const navigate = useNavigate();
  const [datas, setData] = useState([]);
  const approvedby = userDetails[0].username;
  // const axiosJWT = JwtRequestHandler();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");

  function getDetailsOfBid() {
    const SUPPID = biddet[0].SUPPLIERID;
    const BIDID = biddet[0].RFQID;
    const CLIENTID = biddet[0].CLIENTID;
    const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/detailsofbid`,
        {
          clientID: CLIENTID,
          bidID: BIDID,
          suppID: SUPPID,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        }
        // else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    // return () => {
    //   source.cancel();
    // };
  }
  useEffect(getDetailsOfBid, []);

  function acceptBid(a, b, c, d, e, f, g) {
    const rfqid = a;
    const client = b;
    const activedays = c;
    const expiry = d;
    const narration = e;
    const processedby = f;
    const wonby = g;
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/acceptbid`,
        {
          rfqid,
          client,
          activedays,
          expiry,
          narration,
          processedby,
          approvedby,
          wonby,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        alert(response.data.message);
        navigate("/myquotations");
      });
  }

  function calculateGrandTotal(array) {
    const totalAmount = array.reduce(
      (total, current) => Number(total) + Number(current.AMOUNTINCLUSIVEVAT),
      0
    );
    return totalAmount.toFixed(2);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <BuyerNavbar />
        <div style={{ position: "relative", top: "50px" }}>
          <div>
            {datas.map((item, index) => {
              if (index === 0) {
                return (
                  <form>
                    <div className="boxshadow">
                      <h3>Request for Quotation</h3>
                      <div className="bo">
                        <div className="Req_for">
                          <div className="form__group field">
                            <p style={{ width: "118px" }}>Buyer ID</p>

                            <input
                              class="effect"
                              type="text"
                              placeholder="Placeholder Text"
                              disabled
                              value={item.OT_RFQ_ID}
                            />
                            <span class="focus-border"></span>
                          </div>

                          <div className="Requ">
                            <p className="Date_of_creation">Date of Creation</p>

                            <input
                              class="effect_8"
                              type="text"
                              disabled
                              value={moment(item.OT_RFQ_DATE).format(
                                "DD/MM/YYYY"
                              )}
                            ></input>
                          </div>
                        </div>
                        <div className="Req_for">
                          <div>
                            <p>Expiry Date</p>

                            <input
                              style={{ width: "160px " }}
                              class="effect"
                              type="data"
                              required
                              value={moment(item.OT_RFQ_EXPIRY_DATE).format(
                                "DD/MM/YYYY"
                              )}
                              disabled
                            ></input>
                          </div>

                          <div className="form__group field">
                            <p>Active Days</p>

                            <input
                              class="effect"
                              value={item.OT_RFQ_ACTIVE_DAYS}
                              type="number"
                              disabled
                            ></input>
                          </div>
                        </div>

                        <div className="Req_for">
                          <div className="form__group_field1">
                            <p className="Proceed_by">Seller</p>

                            <input
                              class="effect"
                              style={{ color: "black" }}
                              type="text"
                              disabled
                              value={item.OT_RFQ_APPROVED_BY}
                            ></input>
                          </div>
                          <div>
                            <p className="Narration2">Narration</p>

                            <textarea
                              style={{ width: "100%" }}
                              value={item.OT_RFQ_NARRATION}
                              className="textarea"
                              type="text"
                              name="narration"
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="Tab">
            <table className="table">
              <thead>
                <tr className="tr">
                  <th style={{ textAlign: "center" }} className="th">
                    Item Code
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    Item Name
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    Quantity
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    UOM
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    Budget
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    Technical Specs
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    Rate(per Item)
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    Total Rate Charged
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    VAT
                  </th>
                  <th style={{ textAlign: "center" }} className="th">
                    Total Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                {datas.map((item) => (
                  <tr>
                    <td style={{ textAlign: "center" }} className="td">
                      {item.OT_RFQ_ITEM_CODE}
                    </td>
                    <td style={{ textAlign: "center" }} className="td">
                      {item.OT_RFQ_ITEM_NAME}
                    </td>
                    <td style={{ textAlign: "right" }} className="td">
                      {item.OT_RFQ_ITEM_QTY_REQUIRED}
                    </td>
                    <td style={{ textAlign: "center" }} className="td">
                      {item.OT_RFQ_ITEM_UOM}
                    </td>
                    <td style={{ textAlign: "right" }} className="td">
                      {item.OT_RFQ_ITEM_BUDGET_SET.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "center" }} className="td">
                      {item.OT_RFQ_ITEM_TECHNICAL_SPECS}
                    </td>
                    <td style={{ textAlign: "right" }} className="td">
                      {(
                        Number(item.OT_RFQ_ITEM_RATE_CHARGED) /
                        Number(item.OT_RFQ_ITEM_QTY_REQUIRED)
                      ).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </td>
                    <td style={{ textAlign: "right" }} className="td">
                      {item.OT_RFQ_ITEM_RATE_CHARGED.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }} className="td">
                      {item.VATAMOUNT.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td style={{ textAlign: "right" }} className="td">
                      {item.AMOUNTINCLUSIVEVAT.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="Grand">
              <label style={{ fontWeight: 500, fontSize: "20px" }}>
                Grand Total
              </label>
              <input
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderBottom: "1.5px solid",
                  marginLeft: "20px",
                  fontSize: "larger",
                  textAlign: "right",
                }}
                type="number"
                disabled
                value={calculateGrandTotal(datas)}
              ></input>
            </div>
            <div>
              <button
                className="Accept_Bid"
                onClick={() => {
                  const confirmBox = window.confirm(
                    "Are you sure you want to accept this bid?"
                  );
                  if (confirmBox === true) {
                    {
                      acceptBid(
                        datas[0].OT_RFQ_ID,
                        datas[0].OT_RFQ_ID_FROM_CLIENT,
                        datas[0].OT_RFQ_ACTIVE_DAYS,
                        datas[0].OT_RFQ_EXPIRY_DATE,
                        datas[0].OT_RFQ_NARRATION,
                        datas[0].OT_RFQ_PROCESSED_BY,
                        datas[0].OT_RFQ_APPROVED_BY
                      );
                    }
                  }
                }}
              >
                ACCEPT BID
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DetailsOfBid;
