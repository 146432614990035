const initialState = "";
const appliedreducer = (state = initialState, action ) => {
    switch (action.type) {
        case "ALRAPP":
      return action.data;
    default:
      return state;
    }
}

export default appliedreducer;