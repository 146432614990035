import rootReducers from "./reducers/index";

import { legacy_createStore as createStore } from "redux";

function saveToSeshStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    sessionStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}
function loadFromSeshStorage() {
  try {
    const serialisedState = sessionStorage.getItem("persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}
const store = createStore(
  rootReducers,
  loadFromSeshStorage()
);
store.subscribe(() => saveToSeshStorage(store.getState()));
export default store;
