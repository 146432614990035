import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { refreshLogin } from '../redux/actions/actions';

const JwtRequestHandler = () => {
    const axiosJWT = axios.create();
    const dispatch = useDispatch();
    const BASEURL = process.env.REACT_APP_BASEURL;
    const user = useSelector((state) => state.loginreducer);
    var accesstoken = user[0].accessToken;
    var refreshtoken = user[0].refreshToken;
    axiosJWT.interceptors.request.use(
        async (config) => {
            let currentDate = new Date();
            const decodedToken = jwt_decode(accesstoken);
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                const data = await refreshToken();
                config.headers["authtoken"] = "Bearer " + data.accessToken;
            }else{
                config.headers["authtoken"] = "Bearer " + accesstoken;
            }
            return config;
        }, (error) => {
            return Promise.reject(error);
        }
    );

    const refreshToken = async () => {
        try {
            const res = await axios.post(`${BASEURL}/refreshToken`, { token: refreshtoken })
            accesstoken = res.data.accessToken;
            refreshtoken = res.data.refreshToken;
            dispatch(refreshLogin(res.data.accessToken, res.data.refreshToken));
            return res.data;
        } catch (err) {
            console.log(err)
        }
    }
    return axiosJWT;
}

export default JwtRequestHandler;