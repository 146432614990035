import loginreducer from "./loginreducer";
import bidreducer from "./bidreducer";
import appliedreducer from "./appliedreducer";
import loginstatereducer from "./loginstatereducer";
import loginstateredbuyer from "./loginstateredbuyer";
import buyerbidreducer from "./buyerbidreducer";
import { combineReducers } from "redux";

const rootReducers = combineReducers({
  loginreducer,
  bidreducer,
  appliedreducer,
  loginstatereducer,
  loginstateredbuyer,
  buyerbidreducer,
});

export default rootReducers;
